<template>
  <div>
    <md-list>
      <md-list-item
        v-for="user in users.content"
        :key="user.id"
        :class="{ selected: user.id === selectedUser.id }"
        @click="selected(user)"
      >
        <div class="md-list-item-text">
          <span>{{ user.name }}</span>
          <span>{{ user.email }} </span>
        </div>
      </md-list-item>

      <md-list-item
        v-if="!this.users.last"
        class="load-more"
        v-observe-visibility="visibilityChange"
        @click="loadMore"
      >
        {{ $t("load_more") }}...
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_LOAD_MORE, USER_SELECT_DETAILS } from "../../store/actions.types";
export default {
  name: "UserContentList",
  computed: {
    ...mapGetters({
      users: "getUsers",
      selectedUser: "getSelectedUser",
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    selected(user) {
      this.$store.dispatch(USER_SELECT_DETAILS, user);
      this.$emit("selected");
    },
    visibilityChange(isVisible) {
      if (isVisible) {
        this.loadMore();
      }
    },
    loadMore() {
      if (!this.loading) {
        this.loading = true;
        this.$store.dispatch(USER_LOAD_MORE).finally(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.selected {
  background: #ebebeb;
}
.load-more .md-list-item-container >>> .md-list-item-content {
  justify-content: center;
}
</style>