<template>
  <div>
    <md-button
      class="md-raised md-icon-button mg-left-0 btn-back"
      @click="back"
    >
      <font-awesome-icon icon="arrow-left" />
    </md-button>

    <div v-if="selectedUser.id">
      <user-form :user="selectedUser" :updating="true" @onSubmit="submit" />

      <md-button
        class="md-accent md-raised md-icon-button mg-left-0 remove"
        @click="remove"
      >
        <font-awesome-icon icon="trash" />
      </md-button>
    </div>
    <div v-else>
      <md-empty-state
        md-rounded
        md-icon="people"
        :md-label="$t('first_select_a_user')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { User } from "../../common/api/api.service";
import {
  CONFIRM_SHOW,
  USER_RELOAD,
  USER_SELECT_DETAILS,
} from "../../store/actions.types";
import UserForm from "./UserForm.vue";
export default {
  name: "UserContentInfo",
  components: {
    UserForm,
  },
  computed: {
    ...mapGetters({
      selectedUser: "getSelectedUser",
    }),
  },
  methods: {
    back() {
      this.$emit("back");
    },
    update() {
      this.$store.dispatch(USER_RELOAD);
      this.$store.dispatch(USER_SELECT_DETAILS, {});
    },
    submit(user) {
      User.put(user)
        .then(() => {
          this.update();
        })
        .catch((res) => {
          this.$toast.error(res.data);
        });
    },
    remove() {
      this.$store.dispatch(CONFIRM_SHOW, {
        title: "remove?",
        warning: true,
        confirm: "confirm",
        cancel: "cancel",
        onConfirm: () => {
          User.delete(this.selectedUser.id).then(() => {
            this.update();
          });
        },
      });
    },
  },
};
</script>


<style scoped>
@media (min-width: 959px) {
  .btn-back {
    display: none;
  }
}
</style>

