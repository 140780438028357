<template>
  <div class="md-layout">
    <div
      class="
        md-layout-item
        md-size-70
        md-medium-size-100
        md-layout
        md-alignment-top-space-between
      "
    >
      <md-field :class="{ 'md-invalid': $v.form.name.$invalid }">
        <label> {{ $t("name") }} </label>
        <md-input v-model="form.name" />
      </md-field>

      <md-field :class="{ 'md-invalid': $v.form.email.$invalid }">
        <label> {{ $t("email") }} </label>
        <md-input v-model="form.email" />
      </md-field>

      <md-field v-if="!updating">
        <label> {{ $t("password") }} </label>
        <md-input type="password" v-model="form.password" />
      </md-field>

      <md-field v-if="!updating">
        <label> {{ $t("repeat_password") }} </label>
        <md-input type="password" v-model="form.repeatPassword" />
      </md-field>

      <md-field :md-toggle-password="false" v-else>
        <label for="password">{{ $t("password") }}</label>
        <md-input name="password" type="password" value="****" disabled />
        <font-awesome-icon
          @click="changePassword()"
          class="seleccionable input-icon"
          :icon="['far', 'edit']"
        />
      </md-field>

      <!-- TODO -->
      <md-field v-if="false">
        <label>{{ $t("type") }}</label>
        <md-select v-model="form.type" :disabled="disabled">
          <md-option value="ADMIN">{{ $t("admin") }}</md-option>
          <md-option value="READ_ONLY">{{ $t("read_only") }}</md-option>
          <md-option value="VOLSCAN">Volscan</md-option>
        </md-select>
      </md-field>

      <div class="md-layout-item md-size-100">
        <md-button class="md-raised mg-left-0" @click="submit">
          {{ $t("submit") }}
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { User } from "../../common/api/api.service";
import { ALERT, PROMPT_SHOW } from "../../store/actions.types";

export default {
  name: "UserForm",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {
        return {
          type: "READ_ONLY",
        };
      },
    },
    updating: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    user: function () {
      this.initData();
    },
  },

  data() {
    return {
      form: {},
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
        email,
      },
    },
  },

  beforeMount() {
    this.initData();
  },

  methods: {
    initData() {
      this.form = JSON.parse(JSON.stringify(this.user));
    },
    submit() {
      if (this.$v.$invalid) {
        this.$store.dispatch(ALERT, { message: "verify_data" });
      } else if (
        (!this.updating && this.form.password === "") ||
        this.form.password !== this.form.repeatPassword
      ) {
        this.$store.dispatch(ALERT, { message: "passwords_do_not_match" });
      } else {
        this.$emit("onSubmit", this.form);
      }
    },

    changePassword() {
      this.$store.dispatch(PROMPT_SHOW, {
        title: "new_password",
        placeholder: "password",
        password: true,
        confirm: "submit",
        cancel: "cancel",
        onConfirm: (password) => {
          password;
          let userTmp = {
            id: this.form.id,
            password,
          };
          User.updatePassword(userTmp)
            .then(() => {
              this.$toast.success(this.$t("updated!"));
            })
            .catch((err) => {
              console.err(err.response.data);
              this.$toast.error(this.$t(err.response.data));
            });
        },
      });
    },
  },
};
</script>
