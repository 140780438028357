<template>
  <div v-if="users && users.totalElements > 0">
    <div class="md-layout md-alignment-top-space-between">
      <user-content-list
        class="
          md-layout-item md-size-20 md-medium-size-30 md-small-size-100
          content-info
        "
        :class="{ 'md-small-hide': hideList }"
        :style="`height: ${heigth - 15}px`"
        @selected="selected"
      />
      <user-content-info
        class="
          md-layout-item md-size-75 md-medium-size-65 md-small-size-100
          content-info
        "
        :class="{ 'md-small-hide': !hideList }"
        :style="`height: ${heigth - 15}px`"
        @back="back"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserContentInfo from "./UserContentInfo.vue";
import UserContentList from "./UserContentList.vue";
export default {
  name: "UserContent",
  components: {
    UserContentInfo,
    UserContentList,
  },
  props: {
    heigth: Number,
  },
  computed: {
    ...mapGetters({
      users: "getUsers",
    }),
  },
  data() {
    return {
      hideList: false,
    };
  },

  methods: {
    selected() {
      this.hideList = true;
    },
    back() {
      this.hideList = false;
    }
  }
};
</script>

<style scoped>
.content-info {
  margin-top: 15px;
  height: 100px;
  overflow-y: auto;
  padding-right: 15px;
}
</style>