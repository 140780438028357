<template>
  <div>
    <md-button class="md-raised md-icon-button mg-left-0" @click="reloadData">
      <font-awesome-icon icon="redo" />
    </md-button>

    <md-button
      class="md-raised md-icon-button mg-left-0"
      @click="onSearchClick"
    >
      <font-awesome-icon icon="search" />
    </md-button>

    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'UserCreate' }"
    >
      <font-awesome-icon icon="plus" />
    </md-button>

    <user-search ref="user-search" v-show="searchVisible" />

    <md-progress-bar v-if="loading" md-mode="indeterminate" />
    <user-content
      ref="content"
      v-else
      :style="contentHeigth"
      :heigth="height"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserContent from "../../components/user/UserContent.vue";
import UserSearch from "../../components/user/UserSearch.vue";
import {
  TOOLBAR_TITLE,
  USER_LOAD,
  USER_RELOAD,
  USER_UNLOAD,
} from "../../store/actions.types";
export default {
  name: "User",
  components: {
    UserContent,
    UserSearch,
  },
  computed: {
    ...mapGetters({
      users: "getUsers",
    }),
  },
  data() {
    return {
      loading: true,
      contentHeigth: "",
      height: 100,
      searchVisible: false,
    };
  },
  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "users");
    this.loadData();
    window.addEventListener("resize", this.calculateHeight);
  },
  beforeDestroy() {
    this.$store.dispatch(USER_UNLOAD);
    window.removeEventListener("resize", this.calculateHeight);
  },
  methods: {
    async loadData() {
      await this.$store.dispatch(USER_LOAD);
      this.loading = false;
      this.$nextTick(() => {
        this.calculateHeight();
      });
    },
    reloadData() {
      this.$store.dispatch(USER_RELOAD);
    },
    calculateHeight() {
      let min = this.$refs.content.$el.getBoundingClientRect().top;
      let max = window.innerHeight;
      let size = max - min - 17;
      this.height = size;
      this.contentHeigth = `height: ${size}px;`;
    },
    onSearchClick() {
      this.searchVisible = !this.searchVisible;
      this.$nextTick(() => {
        this.calculateHeight();
        if (this.searchVisible) {
          this.$refs["user-search"].$refs["user-search-input"].$el.focus();
        }
      });
    },
  },
};
</script>
